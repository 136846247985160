import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/images/vip/vip0.png';
import _imports_1 from '@/assets/images/vip/vip1.png';
import _imports_2 from '@/assets/images/vip/vip2.png';
import _imports_3 from '@/assets/images/vip/vip3.png';
import _imports_4 from '@/assets/images/vip/vip4.png';
import _imports_5 from '@/assets/images/vip/vip5.png';
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_swiper_slide = _resolveComponent("swiper-slide");
  const _component_swiper = _resolveComponent("swiper");
  return _openBlock(), _createBlock(_component_swiper, {
    class: "swiper",
    "space-between": 10,
    "initial-slide": $props.vip,
    "slides-per-view": "auto",
    "centered-slides": true,
    pagination: {
      clickable: true
    },
    onSlideChange: $options.onSlideChange
  }, {
    default: _withCtx(() => [_createVNode(_component_swiper_slide, {
      class: "vip-swiper__item"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)])),
      _: 1
    }), _createVNode(_component_swiper_slide, {
      class: "vip-swiper__item"
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)])),
      _: 1
    }), _createVNode(_component_swiper_slide, {
      class: "vip-swiper__item"
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1)])),
      _: 1
    }), _createVNode(_component_swiper_slide, {
      class: "vip-swiper__item"
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("img", {
        src: _imports_3,
        alt: ""
      }, null, -1)])),
      _: 1
    }), _createVNode(_component_swiper_slide, {
      class: "vip-swiper__item"
    }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("img", {
        src: _imports_4,
        alt: ""
      }, null, -1)])),
      _: 1
    }), _createVNode(_component_swiper_slide, {
      class: "vip-swiper__item"
    }, {
      default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("img", {
        src: _imports_5,
        alt: ""
      }, null, -1)])),
      _: 1
    })]),
    _: 1
  }, 8, ["initial-slide", "onSlideChange"]);
}