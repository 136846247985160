import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/vip/vip_bg.png';
const _hoisted_1 = {
  class: "level"
};
const _hoisted_2 = {
  class: "level-head"
};
const _hoisted_3 = {
  class: "level-head__swipe"
};
const _hoisted_4 = {
  class: "level-content"
};
const _hoisted_5 = {
  class: "level-content__label"
};
const _hoisted_6 = {
  class: "level-progress__bar"
};
const _hoisted_7 = {
  class: "level-recharge__amount"
};
const _hoisted_8 = {
  style: {
    "margin-bottom": "10px",
    "font-size": "16px"
  }
};
const _hoisted_9 = {
  class: "level-item mb-10"
};
const _hoisted_10 = {
  class: "level-item mb-10"
};
import { router } from '@/router';
import SwiperCarousel from '@/components/SwiperCarousel.vue';
import { useUserStore } from '@/store/user';
import { computed, ref, onMounted } from 'vue';
import { LevelInfo } from '@/api';
import NP from 'number-precision';
export default {
  __name: 'Level',
  setup(__props) {
    const userStore = useUserStore();
    userStore.updateWallet();
    userStore.updateUserInfo();
    const user = computed(() => userStore.user);
    const wallet = computed(() => userStore.wallet);
    const levelData = ref([]);
    const vipIndex = ref(0);
    const onClickLeft = () => {
      console.log(1);
      router.back();
    };
    const _LevelInfo = async () => {
      LevelInfo().then(({
        code,
        data
      }) => {
        if (code === 200) {
          levelData.value = data;
        }
      });
    };
    _LevelInfo();
    onMounted(() => {
      vipIndex.value = user.value.vip;
    });
    const onSlideChange = index => {
      if (typeof index === 'number') {
        vipIndex.value = index;
      }
    };
    return (_ctx, _cache) => {
      var _levelData$value$vipI, _levelData$value$vipI2, _levelData$value$vipI3, _levelData$value$vipI4;
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        class: "nav-transparent__bg",
        fixed: "",
        title: _ctx.$t('Level'),
        "left-arrow": "",
        border: false,
        onClickLeft: onClickLeft
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)), _createElementVNode("div", _hoisted_3, [_createVNode(SwiperCarousel, {
        vip: user.value.vip,
        onSlideChange: onSlideChange
      }, null, 8, ["vip"]), _createElementVNode("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString(levelData.value.length ? _unref(NP).minus((_levelData$value$vipI = levelData.value[vipIndex.value]) === null || _levelData$value$vipI === void 0 ? void 0 : _levelData$value$vipI.recharge, wallet.value.recharge_amount) : '0.00') + " " + _toDisplayString(_ctx.$t('upgrades still to go')), 1), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", {
        style: _normalizeStyle({
          'width': (levelData.value.length ? _unref(NP).divide(wallet.value.recharge_amount, levelData.value[vipIndex.value].recharge) * 100 : 0) + '%'
        })
      }, null, 4)]), _createElementVNode("p", _hoisted_7, [_createElementVNode("span", null, _toDisplayString(wallet.value.recharge_amount), 1), _createTextVNode("/" + _toDisplayString(levelData.value.length ? (_levelData$value$vipI2 = levelData.value[vipIndex.value]) === null || _levelData$value$vipI2 === void 0 ? void 0 : _levelData$value$vipI2.recharge : '0.00'), 1)]), _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('Authority')), 1), _createElementVNode("div", _hoisted_9, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Profit rate')) + ":", 1), _createElementVNode("span", null, _toDisplayString(levelData.value.length ? (_levelData$value$vipI3 = levelData.value[vipIndex.value]) === null || _levelData$value$vipI3 === void 0 ? void 0 : _levelData$value$vipI3.kickback : 0) + "%", 1)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Number of assignments')) + ":", 1), _createElementVNode("span", null, _toDisplayString(levelData.value.length ? (_levelData$value$vipI4 = levelData.value[vipIndex.value]) === null || _levelData$value$vipI4 === void 0 ? void 0 : _levelData$value$vipI4.order_limit : 0), 1)])])])])]);
    };
  }
};