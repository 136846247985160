// import Swiper core and required components
import SwiperCore, { Navigation, A11y } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/swiper.less";
import "swiper/components/navigation/navigation.less";
import "swiper/components/pagination/pagination.less";

// install Swiper components
SwiperCore.use([Navigation, A11y]);

// Import Swiper styles
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    vip: {
      type: Number,
      default: 0
    }
  },
  methods: {
    onSlideChange(e) {
      this.$emit('slideChange', e.activeIndex);
    }
  }
};